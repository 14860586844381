<template>
  <section class="admin-overview pt-5 pb-5 container" id="adminSight">
    <div class="description-title text-center pb-5 pt-5">
      <image class="atl-logo" src="/images/logo.png"></image>
      <h1 >Our <span class="fw-bold">Immersive </span> <span class="admin-heading"
                                                                    >Admin Panel</span></h1>
    </div>
    <div class="container">
      <div class="admin-image">
        <div class="text-center pb-5 pt-5">
          <image class="admin-preview" src="/images/myshop_admin.png" ></image>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "adminPanel"
}
</script>

<style scoped>
h1 {
  color: black;
  font-weight: bold;
}

.admin-heading {
  color: #c11313;
}

.admin-preview {
  width: 90%;
}
.atl-logo {
  height: 60px !important;
  width: 180px !important;
}
</style>