<template>
  <!--Description starts here-->
  <description-view></description-view>
  <!--Description ends here-->
  <!--Strong point Starts here-->
  <strong-point></strong-point>
  <!--Admin Panel overview-->
  <admin-panel></admin-panel>
  <!--Admin Panel Overview Ends Here-->

  <!--Different Pages Section starts-->
  <different-pages></different-pages>
  <!--Different Pages Section Ends-->
  <!--Pricing starts-->
  <pricing-view></pricing-view>
  <!--pricing ends-->
</template>

<script>
import descriptionView from "@/components/descriptionView";
import strongPoint from "@/components/strongPoint";
import adminPanel from "@/components/adminPanel";

import differentPages from "@/components/differentPages";
import pricingView from "@/components/pricingView";


export default {
  name: 'HomeView',
  components: {
    descriptionView, strongPoint, differentPages, pricingView,
    adminPanel
  }

}
</script>
