<template>
  <section id="overview">
    <div class="stups-area pt-100 fix">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-8 col-md-10">

            <div class="section-tittle text-center mb-60">
              <span>MY SHOP OVERVIEW</span>
              <h2>A Glimpse of Our Features</h2>
            </div>
          </div>
        </div>
        <div class="rounded">
      <splide :options="options"  class="rounded">
    <splide-slide class="rounded">
      <img class="img-fluid rounded" src="./../../public/assets/img/gallery/home-layout-1.jpg">
    </splide-slide>
    <splide-slide  class="rounded">
      <img class="img-fluid rounded" src="./../../public/assets/img/gallery/home-layout-2.jpg">
    </splide-slide>
    <splide-slide  class="rounded">
      <img class="img-fluid rounded" src="./../../public/assets/img/gallery/home-layout-3.jpg">
    </splide-slide>
        </splide>
        </div>
        
        <!--      Carousal-->
      </div>
    </div>
  </section>


</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
export default {
  name: "strongPoint",
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
        options: {
          rewind : true,
          width  : 1800,
          height :1000,
          gap    : '1rem',
          drag :true,
          autoplay:true,
         
        },
      };
  }

}
</script>

<style scoped>

</style>