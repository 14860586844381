<template>

  <section class="pages-view pt-5 pb-5 " id="pages">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-8 col-md-10">

          <div class="section-tittle text-center mb-60">
            <span>Dive Deep Into MyShop</span>
            <h2>Our Extensive Outlook and Features</h2>
          </div>
        </div>
      </div>

    </div>
    <div class="container pb-5">
      <div class="row">
        <div class="col-md-7 col-sm-12">
          <div class="admin-image">
            <div class="text-center">
              <image class="page-images" src="assets/img/gallery/banner_1.jpg"></image>

            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12">
          <h1>The Home</h1>
          <p class="pt-2 pb-2">As you will have the very first look when you land into our app, we have such a design that will grab in the very second you visit us.</p>
          <ul>
            <li>Incredbely Fast.</li>
            <li>Global search with both voice and text.</li>
            <li>A list of all the categories.</li>
            <li>Linkable three banner/ads spots.</li>
            <li>Exclusive campaigns just for you.</li>
            <li>Hot sale and latest products.</li>
            <li>Linkable two banner/ads spots.</li>
            <li>Top categories and brands.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container pb-5">
      <div class="row">
        <div class="col-md-5 col-sm-12">
          <h1>Listed Products</h1>
          <p class="pt-2 pb-2">A compact and detailed view of all the products for your convenience so that your customers can find and choose their desired products without any hassle.</p>
          <ul>
            <li>Simple but effective view of the products.</li>
            <li>Powerful filtering options by categories and price.</li>
            <li>Filtering by brands, ratings, and discount options.</li>
            <li>Dynamic searching to find any product in seconds.</li>
          </ul>
        </div>
        <div class="col-md-7 col-sm-12">
          <div class="admin-image">
            <div class="text-center">
              <image class="page-images" src="assets/img/gallery/all_products_laptop.jpg"></image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pb-5">
      <div class="row">
        <div class="col-md-7 col-sm-12 ">
          <div class="admin-image">
            <div class="text-center">
              <image class="page-images" src="assets/img/gallery/products_details_laptop_banner.jpg"></image>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 pb-3 second-preview">
          <h1>Product Overview</h1>
          <p class="pt-2 pb-2">We have created such a product presentation that will appeal your customers everytime they visit</p>
          <ul>
            <li>Standard product details box.</li>
            <li>Add to cart and wishlist options.</li>
            <li>Sharable links for social platforms.</li>
            <li>Product specs,ratings and reviews option.</li>
            <li>Top selling and hot products suggestion.</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container pb-5">
      <div class="row">
        <div class="col-md-5 col-sm-12 pb-3 second-preview">
          <h1>Unlimited Campaigns</h1>
          <p class="pt-2 pb-2">Grab your customers by time-limited campaigns with exclusive deals.</p>
          <ul>
            <li>Exclusive campaign products with short listed products.</li>
            <li>Campaign time dynamic controller.</li>
            <li>Detailed view of all the listed products for each campaigns.</li>
           
          </ul>
        </div>
        <div class="col-md-7 col-sm-12 ">
          <div class="admin-image">
            <div class="text-center">
              <image class="page-images" src="assets/img/gallery/campaign.jpg"></image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pb-5">
      <div class="row">
        <div class="col-md-7 col-sm-12 ">
          <div class="admin-image">
            <div class="text-center">
              <image class="page-images" src="assets/img/gallery/coupon.jpg"></image>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 pb-3 second-preview">
          <h1>Dynamic Coupons</h1>
          <p class="pt-2 pb-2">We have added options for unlimited coupons that will certainly reach your customers more faster than ever.</p>
          <ul>
            <li>Coupon discount and expiry.</li>
            <li>Coupon discount amount set.</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


// optional style for arrows & dots
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "differentPages",

}
</script>

<style scoped>
.atl-logo {
  width: 200px;
}

h1 {
  color: black;
  font-weight: bold;
}

.pages-title {
  color: #c11313;
}

.page-images {
  width: 100%;
  height: 100%;
}

.admin-image {
  justify-content: center;
  align-items: center;

  background-color: white;
  padding: 25px;
  transition: all 0.35s ease;
}

.admin-image:hover {
  box-shadow: none;
  transform: translateY(8px);
}

li {
  color: #F98129;
  list-style: square;
}
@media (max-width: 766px){
  
 .second-preview{
    order: 1 !important;
 }
  }
</style>