<!--navbar ends here-->
<template>
 <!--Navbar Start-->
 <header class="header-transparent" >
    <nav class="navbar navbar-expand-lg  ">
      <div class="container-fluid ">
        <div class="logo">
          <a href=""
            ><img class="atl-logo ms-4 mt-2" src="assets/img/logo/logo.png" alt=""
          /></a>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
         
        >
          <span class="navbar-toggler-icon" ></span>
        </button>
        <div
          class="collapse navbar-collapse text-center main-menu "
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 align-items-center ">
            <li class="nav-item ms-4">
              <a class="nav-link active" aria-current="page" href="#hero">Home</a>
            </li>
            <li class="nav-item ms-4">
              <a class="nav-link" aria-current="page" href="#features">Features</a>
            </li>
            <li class="nav-item ms-4">
              <a class="nav-link" aria-current="page" href="#overview">Overview</a>
            </li>
            <li class="nav-item ms-4">
              <a class="nav-link" aria-current="page" href="#pages">Explore</a>
            </li>
            <li class="nav-item ms-4">
              <a class="nav-link" aria-current="page" href="#pricing">Pricing</a>
            </li>
          </ul>

          <div class="header-right-btn f-right ">
            <a target="_blank" href="https://myshop.ayaantec.com/admin/login" class="btn_0 me-2 text-decoration-none d-none d-lg-inline ">Login</a>
            <a href="https://myshopdemo.ayaantec.com/documentation" class="btn_0 text-decoration-none d-none d-lg-inline">Documentation</a>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <!-- Navbar End -->
</template>

<script>
export default {
  name: "mainHeader",
  
}
</script>

<style scoped>
.atl-logo {
  height: 60px !important;
  width: 180px !important;
}

.main-menu ul>li a {
    font-family: "Jost",sans-serif;
    color: #192839;
    font-weight: 400;
    padding: 29px 7px;
    display: block;
    font-size: 18px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    text-transform: capitalize;
    position: relative
}
.main-menu ul>li a::after {
    content: "";
    position: absolute;
    width: 0;
    bottom: 0px;
    right: 5px;
    left: -5px;
    height: 3px;
    z-index: 1;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
    background: #FF4495
}
 .main-menu ul>li:hover>a {
    color: #FF4495
}

.main-menu ul>li:hover>a::after {
    left: 0;
    right: auto;
    width: 100%;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s
}
@media (max-width: 991px) {
  .header-transparent{
    position: relative !important;
  }
  .ms-4{
    margin-left: 0rem!important;
  }
  .main-menu ul>li a{
    padding: 17px 7px !important;
  }
}


 

</style>