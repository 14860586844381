<template>
  <section id="features"></section>
  <section class="categories-area bottom-padding position-relative">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-8 col-md-10">
          <div class="section-tittle text-center mb-60">
            <span>Made with the best Technologies</span>
            <h2>Provides Awesome Service With Latest Tools</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5><a href="" class="disable text-decoration-none">All In One E-commerce</a></h5>
            <div class="card-icon">
              <img src="assets/img/icon/all_in_one.png" class="pwa-icon-second" alt="" />
            </div>
            <div class="cat-cap">
              <p>Fullfill all your need through one application.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5><a href="" class="disable text-decoration-none">Vue JS Ready Frontend</a></h5>
            <div class="card-icon">
              <img src="assets/img/icon/vue.png" class="pwa-icon" alt="" />
            </div>
            <div class="cat-cap">
              <p>Get a seamless user experience in every portion.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5><a href="" class="disable text-decoration-none">Laravel Based Backend</a></h5>
            <div class="card-icon">
              <img src="assets/img/icon/laravel.png" class="pwa-icon" alt="" />
            </div>
            <div class="cat-cap">
              <p>Ensures a powerful and secure backend architecture.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5><a href="" class="disable text-decoration-none">Progressive Web App</a></h5>
            <div class="card-icon">
              <img src="assets/img/icon/pwa.png" alt="" class="pwa-icon" />
            </div>
            <div class="cat-cap">
              <p>Get the best experience of the software.</p>
            </div>
          </div>
        </div>
      
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5><a href="" class="disable text-decoration-none">Incredible POS Option</a></h5>
            <div class="card-icon">
              <img src="assets/img/icon/pos_icon.png" class="pwa-icon-second" alt="" />
            </div>
            <div class="cat-cap">
              <p>Get complete POS functioanlity anytime,anywhere.</p>
            </div>
          </div>
        </div>
       
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5><a href="" class="disable text-decoration-none">Dynamic Campaigns</a></h5>
            <div class="card-icon">
              <img src="assets/img/icon/campaign.png" alt="" class="pwa-icon-second" />
            </div>
            <div class="cat-cap">
              <p>Run occasional campaigns anytime.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5>
              <a href="" class="disable text-decoration-none"
                >Customize as you like</a
              >
            </h5>
            <div class="card-icon">
              <img src="assets/img/icon/customize.png" class="pwa-icon-second" alt="" />
            </div>
            <div class="cat-cap">
              <p>Change themes and menus from one place.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 text-center">
          <div class="single-cat mb-50">
            <h5>
              <a href="" class="disable text-decoration-none"
                >Mobile App Coming</a
              >
            </h5>
            <div class="card-icon">
              <img src="assets/img/icon/mobile.png" class="pwa-icon" alt="" />
            </div>
            <div class="cat-cap">
              <p>Flutter based mobile application coming soon.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shape-s1 d-none d-xxl-block">
      <img src="assets/img/gallery/shape-1.png" alt="" />
    </div>
    <div class="shape-s2 d-none d-xl-block">
      <img src="assets/img/gallery/shape-2.png" alt="" />
    </div>
  </section>
</template>

<script>
export default {
  name: "descriptionView",
};
</script>

<style scoped>
.pwa-icon {
  height: 100px;
  width: 180px;
}
.pwa-icon-second {
  height: 140px;
  width: 180px;
}

.single-cat {
  height: 350px;
}

.card-icon {
  padding-bottom: 20px;
}

a.disable {
  pointer-events: none;
  cursor: default;
}
</style>
