<template>
  <section id="hero">
    <div class="slider-area position-relative">
      <div class="single-sliders slider-height  slider-bg1 d-flex align-items-center">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-6 col-sm-12">
              <div class="hero-caption">
                <span>Single Vendor E-Commerce Script</span>
                <h1>MY SHOP</h1>
                <p> Premium Quality PWA Based on Vue and Laravel</p>
                <a target="_blank" href="https://myshop.ayaantec.com/" class="btn_0 hero-btn me-2 mb-2 text-decoration-none">View Frontend</a>
                <a target="_blank" href="https://myshop.ayaantec.com/admin/login" class="btn_0 hero-btn text-decoration-none">Explore Backend</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "mainHero",
};
</script>

<style scoped>
@media only screen and (min-width: 768px) and (max-width: 991px){
  
.hero-caption span {
    font-size: 16px;
}
}
</style>
