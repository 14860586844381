<template>
  <section id="footer">
   
    <footer>
      <div class="footer-wrapper section-img-bg2" data-background="assets/img/gallery/footer-bg.jpg">

        <div class="footer-area footer-padding">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-8">
                <div class="single-footer-caption mb-50">
                  <div class="single-footer-caption mb-30">

                    <div class="footer-logo mb-35">
                      <a href=""><img class="atl-logo" src="assets/img/logo/logo.png" alt=""></a>
                    </div>
                    <div class="footer-tittle">
                      <div class="footer-pera">
                        <p>Best IT Solution in Bangladesh</p>
                      </div>

                      <ul class="footer-social">
                        <li><a href="https://www.facebook.com/ayaantechlimited/"><i class="fab fa-facebook"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/ayaan-tech-limited"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCfHg6yMJ967P4ei_JxW--jw"><i class="fab fa-youtube"></i></a></li>
                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Navigation</h4>
                    <ul>
                      <li><a href="#hero">Home</a></li>
                      <li><a href="#features">Features</a></li>
                      <li><a href="#overview">Overview</a></li>
                      <li><a href="#pages">Explore</a></li>
                      <li><a href="#pricing">Pricing</a></li>
                    </ul>
                  </div>
                </div>
              </div>
          
              <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle mb-50">
                    <h4>Contact Us</h4>
                    <ul>
                      <li><a href="https://goo.gl/maps/b2Lgdyh1nAYre6fR8"> House:24, Road:14, Niketon Gulshan-1, Dhaka Bangladesh 1219</a></li>
                      <li><a href="mailto:connect@ayaantec.com">connect@ayaantec.com</a></li>
                      <li><a href="tel:+8801973198574">+88 01973198574</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-bottom-area">
          <div class="container">
            <div class="footer-border">
              <div class="row">
                <div class="col-xl-12 ">

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </footer>
    <!-- End of .container -->
    <!-- End of .container -->
  </section>
</template>

<script>

export default {
  name: "mainFooter"
}
</script>

<style scoped>
.footer-wrapper {
  background: url("../../../public/assets/img/gallery/footer-bg.jpg") !important;
}

.atl-logo {
  height: 60px !important;
  width: 180px !important;
}
ul{
  padding-left: 0;
}
</style>