<template>
  <master-view></master-view>
</template>

<style>


</style>
<script>
// @ is an alias to /src
import masterView from './views/masterView';

export default {
  name: 'HomeView',
  components: {
    masterView,
  }
}
</script>
