<template>

<!--navbar starts-->
  <main-header></main-header>
  <!--navbar ends here-->
  <!--hero starts here-->
 <main-hero></main-hero>

  <!--hero ends here-->
<router-view></router-view>
 

  <!--footer starts-->
<main-footer></main-footer>

  <!--footer ends-->
</template>

<script>
import mainHeader from './pages/mainHeader.vue';
import mainHero from './pages/mainHero.vue';
import mainFooter from './pages/mainFooter.vue';
export default {
  name: "masterView",
  components:{
  mainHeader,mainHero,mainFooter,
}
};
</script>

<style scoped>

</style>