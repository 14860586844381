<template>
  <section class="pricing-card-area section-padding fix" id="pricing">
    <div class="container-fluid p-bg pt-70">
      <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-7 col-md-10">
          <div class="section-tittle text-center mb-15">
            <span>Pricing Plan</span>
            <h2>The best and Affordable Pricing</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container p-bg">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="nav-one"
            role="tabpanel"
            aria-labelledby="nav-one-tab"
        >
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="pricing-card">
                <div class="single-card text-center mb-30">
                  <div class="card-top">
                    <div class="cat-icon">
                      <img src="assets/img/icon/price1.svg" alt=""/>
                    </div>
                    <span>Standard</span>
                  </div>
                  <div class="card-mid">
                    <h4>$150</h4>
                  </div>
                  <div class="card-bottom">
                    <ul>
                      <li>Lifetime update</li>
                      <li>Permitted for 1 domain</li>
                      <li>6 months technical support</li>
                      <li>For personal project</li>
                      <li>For commercial project</li>
                      <li>Free installation (cpanel)</li>
                      <li><s>Uncompiled vue source code</s></li>
                      <li><s>1 hr Live support (AnyDesk)</s></li>
                    </ul>
                    <a  href="javascript:" class="btn_10 get-btn text-decoration-none">Get Started</a>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="pricing-card">
                <div class="single-card text-center mb-30">
                  <div class="card-top">
                    <div class="cat-icon">
                      <img src="assets/img/icon/price2.svg" alt=""/>
                    </div>
                    <span>Extended</span>
                  </div>
                  <div class="card-mid">
                    <h4>$500</h4>
                  </div>
                  <div class="card-bottom">
                    <ul>
                      <li>Lifetime update</li>
                      <li>Permitted for 1 domain</li>
                      <li>6 months technical support</li>
                      <li>For personal project</li>
                      <li>Free installation (cpanel)</li>
                      <li>Uncompiled vue source code</li>
                      <li>For commercial prjoect</li>
                      <li>1 hr Live support (AnyDesk)</li>
                    </ul>
                    <a href="javascript:" class="btn_10 get-btn text-decoration-none">Get Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "pricingView",

};
</script>

<style scoped>
.pricing-card {
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
}

.card-bottom li + li {
  border-top: 1px solid #f0f0f0;
}
</style>
